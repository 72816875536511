<template>
	<div class="noticePage">
		<BACK title="关注" classStyle="style2" :history="history"></BACK>
		<div class="noticeTitle">关注的人</div>
		<van-list v-model:loading="loading" :finished="finished" :finished-text="finishTxt" @load="loadData">
			<div class="main">
				<div class="noticeLine" v-for="(item,index) in listData" :key="index">
					<div class="noticeLeft">
						<div class="noticeImg"><img :src="item.avatar" /></div>
						<div class="tiezhuzhe">
							<div class="name">
								{{item.nickname}}
							</div>
							<div class="time" v-if="false">官方点赞，给你欧气加持~官方点赞，给你欧气加持~官方点赞，给你欧气加持~官方点赞，给你欧气加持~</div>
						</div>
					</div>
					<div class="gongzhuBtn" @click="doNotice(item.uid,index)">{{item.is_follow==1?'已关注':'关注'}}</div>
				</div>
			</div>
		</van-list>
	</div>
</template>

<script>
	import {
		defineComponent
	} from 'vue';
	import BACK from '@/components/back/back.vue';
	import {
		List
	} from 'vant';
	import request from '@/service/request';
	import {
		Toast
	} from 'vant';
	import common from '@/util/common';

	export default defineComponent({
		name: 'content',
		components: {
			BACK,
			[List.name]: List
		},
		mounted() {
			document.querySelector("body").setAttribute("style",
				"background: linear-gradient(0deg, #EDEDD0, #DBEFE7);");
		},
		unmounted() {
			document.querySelector("body").setAttribute("style",
				"background: linear-gradient(#95C5B5, #64A4A4) no-repeat;");
		},
		data() {
			return {
				listData: [],
				loading: false, //是否处于加载状态，加载过程中不触发 load 事件
				page: 1,
				finishTxt: "没有更多了",
				finished: false,
			}
		},
		created() {
			this.history = this.$route.query.history;
		},
		methods: {
			loadData() {
				this.getList();
			},
			getList() {
				request.apiAxios({
					method: 'post',
					url: request.FOLLOWLIST,
					data: {
						page: this.page,
						token: window.localStorage.getItem('token')
					}
				}).then((res) => {
					if (res.status) {
						if (res.data && res.data.length > 0) {
							for (var i = 0; i < res.data.length; i++) {
								res.data[i].is_follow = 1;
							}
							if (this.listData.length == 0) {
								this.listData = res.data;
							} else {
								this.listData = this.listData.concat(res.data);
							}
							this.finished = false;
						} else {
							this.finished = true;
						}
						this.page++;
					}
					this.loading = false;
				}).catch((err) => {
					console.log(err);
					this.finished = true;
				})
			},
			doNotice(follow_uid, index) {
				if (common.isLogin()) {
					var smData = {};
					smData.token = window.localStorage.getItem('token');
					smData.follow_uid = follow_uid;
					request.apiAxios({
						method: 'post',
						url: request.FOLLOW,
						data: smData
					}).then((res) => {
						if (res.status) {
							if (res.data.is_follow == 1) {
								this.listData[index].is_follow = 1;
								Toast("关注成功！");
							} else {
								this.listData[index].is_follow = 0;
								Toast("取消关注成功！");
							}

						} else {
							Toast(res.message);
						}
					}).catch((err) => {
						console.log(err);
					})
				}
			}
		}
	})
</script>

<style lang="less" scoped="scoped">
	.noticePage {
		padding: 0.26rem 0.26rem 0;
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0.2rem;
		overflow-y: auto;

		.noticeTitle {
			width: 1.68rem;
			height: 0.63rem;
			background: #73D1C7;
			box-shadow: 0px 1px 2px 0px rgba(161, 161, 133, 0.36);
			border-radius: 0.32rem;
			margin-bottom: 0.3rem;
			text-align: center;
			font-size: 0.3rem;
			font-family: SimHei;
			font-weight: 400;
			color: #F8FAF5;
			line-height: 0.63rem;
		}

		.noticeLine {
			display: flex;
			align-items: center;
			margin-bottom: 0.26rem;
			background: #F8FAF5;
			box-shadow: 0px 1px 2px 0px rgba(161, 161, 136, 0.36);
			border-radius: 0.14rem;
			padding: 0.16rem 0.22rem;
		}

		.noticeLeft {
			margin-right: 0.2rem;
			flex: auto;
			display: flex;
			color: #5D6262;
		}

		.noticeImg {
			width: 0.88rem;
			height: 0.88rem;
			background: #EBEDDE;
			border-radius: 0.07rem;
			display: flex;
			justify-content: center;
			align-items: center;
			margin-right: 0.2rem;

			img {
				max-width: 100%;
				max-height: 100%;
				border-radius: 0.07rem;
			}
		}

		.tiezhuzhe {
			display: flex;
			flex-direction: column;
			overflow: hidden;
			white-space: nowrap;
			max-width: 3.5rem;

			.name {
				display: flex;
				align-items: center;
				font-size: 0.34rem;
				margin-bottom: 0.14rem;
				color: #5D6262;
			}

			.time {
				font-size: 0.25rem;
				color: #5D6262;
			}
		}

		.guangfangtag {
			width: 0.78rem;
			height: 0.38rem;
			margin-left: 0.4rem;
			flex: none;
		}

		.gongzhuBtn {
			flex: none;
			width: 1.22rem;
			height: 0.64rem;
			background: #73D1C7;
			box-shadow: 0px 1px 2px 0px rgba(161, 161, 133, 0.36);
			border-radius: 0.32rem;
			line-height: 0.64rem;
			text-align: center;
			color: #F8FAF5;
			font-size: 0.3rem;
		}
	}
</style>
